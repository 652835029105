import dynamic from 'next/dynamic'

const Angled = dynamic(() => import('./heros/angled'))
const Split = dynamic(() => import('./heros/split'))
const Centered = dynamic(() => import('./heros/centered'))
const Video = dynamic(() => import('./heros/video'))
const Background = dynamic(() => import('./heros/background'))
const ImageGrid = dynamic(() => import('./heros/image-grid'))

export default function Hero({ fields, first, sys, includes, ...rest }) {
  let type = null;

  console.log('fields.type', fields.type)
  switch (fields.type) {
    case 'Angled':
      type = <Angled first={first} fields={fields} sys={sys} includes={includes} {...rest} />;
      break;
    case 'Split':
        type = <Split first={first} fields={fields} sys={sys} includes={includes} {...rest} />;
        break;
    case 'Centered':
      type = <Centered fields={fields} sys={sys} includes={includes} {...rest} />;
      break;
      case 'Image Grid':
        // @ts-ignore
        type = <ImageGrid first={first} fields={fields} sys={sys} includes={includes} {...rest} />;
        break;
      case 'Video':
      // @ts-ignore
      type = <Video first={first} fields={fields} sys={sys} includes={includes} {...rest} />;
      break;
    case 'Background':
      type = <Background first={first} fields={fields} sys={sys} includes={includes} {...rest} />;
      break;
    default:
      type = null;
  }

  return type;
}
